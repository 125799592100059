import React from 'react';
import { graphql } from 'gatsby';
import { isDummyOrTestContent, showTestContent, randomKey } from '../../functions/common';
import { DevAids } from '../dev-aids';

export const CrossSiteTopBannerMessageBlock = ({ nodes }) => {
  const blocks = nodes.nodes.map((item, index: Number) => {
    const enabled = item?.field_enabled;
    const body = item?.body?.processed;
    const info = item?.info;

    return body &&
      enabled === true &&
      (!isDummyOrTestContent(info) || (isDummyOrTestContent(info) && showTestContent)) ? (
      <React.Fragment key={`${index}-${info}-${randomKey()}`}>
        <DevAids node={item} />
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </React.Fragment>
    ) : (
      null
    );
  }).filter((item) => item !== null);

  return blocks.length > 0 ? (
    <div className={`grid grid-cols-1 block_cross-site-top-banner`}>{blocks}</div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export const fragment = graphql`
  fragment CrossSiteTopBannerMessageBlockQuery on block_content__cross_site_top_banner_messageConnection {
    nodes {
      id
      drupal_id
      internal {
        type
      }
      body {
        processed
      }
      info
      field_enabled
    }
  }
`;
