import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '../elements/link';
import { RawText, testEnv, getParkNames, isDummyOrTestContent } from '../../functions/common.js';

const SiteLogoBlock = memo(() => {
  const park = process.env.GATSBY_PARK,
    data = useStaticQuery(graphql`
      query {
        allBlockContentSiteLogoPanel {
          nodes {
            drupal_id
            info
            field_site_for_block {
              drupal_internal__target_id
            }
            field_site_logo_class
            body {
              value
            }
            relationships {
              field_site_logo {
                ...MediaDataSvgIcon
              }
            }
          }
        }
      }
    `);
  const result = data.allBlockContentSiteLogoPanel.nodes.map((item, index: Number) => {
    if (
      item.field_site_for_block.drupal_internal__target_id === park &&
      isDummyOrTestContent(item.info) === false
    ) {
      const logoImage =
          item.relationships.field_site_logo.customLocalFieldMediaImage1?.publicURL || null,
        logoImageAlt = RawText(item.body?.value ? item.body.value : ''),
        siteLogoClass = item.field_site_logo_class ? item.field_site_logo_class : '';

      // Prevent build errors if attempting to render an unpublished image
      if (!logoImage) {
        testEnv().devMode
          ? console.warn(
              `[ISSUE - Images]:No image found in Site Logo block ID ${item.drupal_id}.\nThis can mean you\'re trying to render an unpublished media item, or the source image does not have a matching File node in GrahpQL\n`
            )
          : null;
      }
      return (
        <div className="masthead__logo hide-text block_site_logo" key={`${index}_${logoImage}`}>
          <Link className="masthead__logo-anchor hide-text" to="/">
            {getParkNames().fullName}
            {/* Allow graceful fallback for Gatsby not correctly saving a childImageSharp version */}
            {logoImage ? (
              <img src={logoImage} alt={logoImageAlt} loading="eager" className={siteLogoClass} />
            ) : (
              <React.Fragment key={item.id}></React.Fragment>
            )}
          </Link>
        </div>
      );
    }
  });
  return <>{result}</>;
});

export default SiteLogoBlock;
