import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PageHeader from '../components/structure/header';
import FooterLogosPanel from '../components/structure/footer-logos-panel';
import FooterNavigationPanel from '../components/structure/footer-navigation-panel';
import { CrossSiteTopBannerMessageBlock } from '../components/blocks/cross-site-top-banner';
import FooterSharedParksLogoPanel from '../components/structure/footer-parks-logo-panel';

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query {
      allBlockContentCrossSiteTopBannerMessage {
        nodes {
          id
          drupal_id
          internal {
            type
          }
          body {
            processed
          }
          info
          field_enabled
        }
      }
    }
  `);

  return (
    <>
      <div className="masthead">
        <CrossSiteTopBannerMessageBlock nodes={data.allBlockContentCrossSiteTopBannerMessage} />
        <div className="masthead__inner clearfix">
          <PageHeader />
        </div>
      </div>
      <div className="main-content" id="main-content" role="main">
        {children}
      </div>
      <div className="footer" role="contentinfo">
        <FooterLogosPanel />
        <FooterNavigationPanel />
        <FooterSharedParksLogoPanel />
      </div>
    </>
  );
}
