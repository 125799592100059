import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { isDummyOrTestContent, showTestContent } from '../../functions/common';

export default function SharedParksLogoBlock() {
  const data = useStaticQuery(
    graphql`
      query {
        blockContentSharedFooterParksLogoPanel {
          info
          body {
            value
          }
        }
      }
    `
  );
  if (!data || (isDummyOrTestContent(data?.blockContentSharedFooterParksLogoPanel?.info) === true) && !showTestContent) {
    return <></>;
  }

  return data?.blockContentSharedFooterParksLogoPanel?.body?.value ? (
    <div
      className="grid-col grid-col--12 block_shared_parks_logo"
      dangerouslySetInnerHTML={{
        __html: data.blockContentSharedFooterParksLogoPanel.body.value,
      }}
    />
  ) : (
    <></>
  );
}
